import { render, staticRenderFns } from "./ListMobile.vue?vue&type=template&id=453c2a77&scoped=true"
import script from "./ListMobile.vue?vue&type=script&lang=js"
export * from "./ListMobile.vue?vue&type=script&lang=js"
import style0 from "./ListMobile.vue?vue&type=style&index=0&id=453c2a77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453c2a77",
  null
  
)

export default component.exports